.dndflow {
    /* flex-direction : column;
    display        : flex;
    height         : 100%;
    margin         : -24px -20px -24px -24px; */
  }
  .react-flow{
      position         : unset;
      background-size  : cover;
      background-image : url('/src/assets/img/FLowBG.webp');
  }

  .react-flow__pane{
      height: 100vh;
    }
    
    .react-flow__renderer{
        height: 100vh;
    }

    .updateDIV{
      width            : 363px;
      background-color : #FFF;
      border-left      : 1px solid #E8E8EF;
      box-sizing       : border-box;
      /* padding-top      : 85px;
      padding-left     : 20px; */
      height           : 100%;
      position         : absolute;
      right            : 10px;
      top              : 10px;
      z-index          : 4;
    }
  
  .dndflowDIV {
    /* border-right: 1px solid #eee; */
    /* padding: 15px 10px; */
    /* font-size: 12px; */
    /* background: #fcfcfc; */
    /* z-index: 10; */

    width            : 300px;
    background-color : #FFF;
    border-right     : 1px solid #E8E8EF;
    box-sizing       : border-box;
    /* padding-top      : 46px; 22+24 */
    /* padding-left     : 47px; 23+24 */
    /* height           : 100%; */
    position         : absolute;
    z-index          : 10;

    
  }
  
  .dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
  }

  .cursorgrab{
    cursor: grab;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 180px;
    }
  }