/* ---------------------------------------------------
    COLOR VARIABLE
----------------------------------------------------- */

$colors:(
white     : #ffffff,
black     : #000000,
primary0  : #f2f6ff,
primary10 : #c4d5ff,
primary20 : #95b3ff,
primary30 : #6a93fc,
primary40 : #4678f6,
primary50 : #2960ec,
primary60 : #164cd4,
primary70 : #0a3bb7,
primary80 : #042e98,
primary90 : #012278,
primary100: #001959,
neutral0  : #f5f7fc,
neutral10 : #e5e8f5,
neutral20 : #d6dae9,
neutral30 : #c5c9db,
neutral40 : #b2b7ca,
neutral50 : #9ca1b4,
neutral60 : #84889b,
neutral70 : #686c7c,
neutral80 : #4b4e5b,
neutral90 : #2e3039,
neutral100: #141519,
danger0   : #ffebf1,
danger10  : #ff97b6,
danger20  : #f75082,
danger30  : #de245c,
danger40  : #c72958,
danger50  : #ac0135,
warning0  : #fff9f0,
warning10 : #f6c985,
warning20 : #eda12f,
warning30 : #bc7b19,
warning40 : #8b5b13,
warning50 : #593c10,
success0  : #ebfff5,
success10 : #9fface,
success20 : #62eba9,
success30 : #3dcc87,
success40 : #129455,
success50 : #00592e,
info0     : #f0f9ff,
info10    : #83caf3,
info20    : #2ea2e6,
info30    : #197db7,
info40    : #125d88,
info50    : #103e59,

);

/* ---------------------------------------------------
    COLOR FUCNTION
----------------------------------------------------- */

@function color($color){
    @return map-get($map: $colors, $key:$color )
};

/* ---------------------------------------------------
    COLOR CLASS LOOP
----------------------------------------------------- */

@each $name, $color in $colors {
    .color-#{$name} {
        color : #{$color} !important;
    }

    .bg-color-#{$name} {
        background-color : #{$color} !important;
    }
}

