#Domain{
    .circle{
        display: flex;
width: 34px;
padding: 7px 0px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 70px;
background:  #F2F6FF;
border-radius: 70px;
background: var(--primary0, #F2F6FF);
height: 34px;
    }

    .number{
        color:  #2960EC;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "DM Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
letter-spacing: 0.1px;
    }

    .justifybetween{
        justify-content: space-between !important;
    }
}