#viewDetailComponent,#Earnpointmodal{
    margin-top      : 8px;
    margin-bottom   : 8px;
    max-height      : 200px;
    overflow-y      : scroll;
    z-index         : 1000;
    max-width       : 200px;
    background-color: white;
    border          : 0.5px solid #D6DAE9;
    box-shadow      : 0px 3px 6px rgba(15, 15, 15, 0.1), 0px 9px 24px rgba(15, 15, 15, 0.2);
    border-radius   : 4px;
    padding         : 6px 4px;


    .singleItem{
        padding: 8px 12px !important;
        border-radius: 6px !important;
    }

    .wrapper{
        transition: background-color ease 0.2s;
    }

    .wrapper:hover{
        background-color: color(primary0);
        border-radius: 6px;
    }

    .wrapper:last-child:hover{
        background-color: color(danger0) !important;
        
        .dropdownBody{
            color: color(danger30) !important;
        }
        .iconPart svg path{
            fill: color(danger30);
        }
    }


}