#TemplateList{

    .tempales{
        padding: 32px;
    }

    

    .singleTempalteBox{
        border: 2px solid transparent;
        border-radius: 4px;
        margin-left: 10px;
        margin-right: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            border: 2px solid color(primary50);
            border-radius: 4px;
        }
        &.active{
            border: 2px solid color(primary50);
            border-radius: 4px;
        }
    }

    

    .templateSection{
        padding: 0 15px;
        background-color: color(neutral0);
        height: 312px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .templateRow{
        display: flex;
        flex-wrap: wrap;
    }

    .titleSection{
        padding: 16px;
        border: 1px solid color(neutral0);
        border-top: 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .footerPart{
        position: fixed;
        bottom: 0px;
        right: 0px;
        display: flex;
        padding: 16px 32px 16px 0px;
        border-top: 0.5px solid color(neutral10) !important;
        justify-content: flex-end;
        width: 100%;
        background-color: white;
    }
}