#analytics{
    padding: 24px;

    .Heading22R{
        color: #000000;
        padding-bottom: 24px;
        border-bottom: 0.5px solid #E6E6E6;
    }
    .headingDate{
        font-weight: normal;
    }

    .graphBox{
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 16px;
        padding: 40px;

    }
    .Mui-selected{
        border: 2px solid white;
        outline: 0px;
        color: color(primary50);
        font-size: 16px;
    }
    .MuiTabs-indicator{
        color: color(neutral80);
        background-color: color(primary50);
    }

    .subtitle1{
        color: #000000;
    }
}