.NavselectedItem :hover{
    svg path {
        fill: color(primary50) 
    }
}
#sidebar {  
    height          : 100vh;
    z-index         : 990 !important;
    top             : 0px;
    position        : sticky;
    width           : 5vw;
    max-width       : 72px;
    box-shadow      : 0px 4px 4px rgba(128, 128, 128, 0.32);
    background-color: color(neutral0);
    border-right    : 0.5px solid color(neutral20);
    ;


    .borderBottomItems{
        box-shadow: 0px 1px 0px color(neutral20);
    }
        
        
        
        


        .pSide{
            height : 100%;
            background-color: color(neutral0);
            width: 5vw;
            max-width: 72px;
            box-shadow: 1px 0px 0px color(neutral20);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .subBrandImageBox{
            position: absolute;
            top: 30px;
            right: 6px;
        }
        
        .subBrandImage{
            border: 1px solid #F2F4F7;
        }

        .logoutMenu{
            z-index: 1000;
            width: 270px;
            position: absolute;
            bottom: 10px;
            left: 45px;
            background: #FCFCFD;
            box-shadow: $shadow-custom-dropdown;
        }

        .singleItemLogoutMenu{
            padding: 8px 0px 8px 24px;
        }


        .userProfileIcon{
            padding: 12px 8px;
            box-shadow: 0px -1px 0px color(neutral20);
        }

        .borders{
            border-bottom: 0.5px solid color(neutral10) !important;
        }

        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height : 60px;
        }

        .logo img:hover, .activeLogo{
            box-shadow: 0px 0px 0px 4px rgba(55, 112, 251, 0.15);
            transition:  all ease-in-out .2s;
            margin-bottom: 2px;
        }

        .bar {
            position      : absolute;
            border-bottom : 0.5px solid #F0F0F0 !important;
            right         : 0%;
            top           : 59px;
            width         : 100%;
        }

        .navPartial {
            width      : 5vw;
            max-width  : 72px;
            text-align : center;
            overflow-y : auto;
        }
        
        

        .icons {
            cursor  : pointer;
        }

        .active path{
            // fill       : color(primary50);
            transition : ease 0.3s;
        }
        .active{
            background-color: none !important;
            border-radius   : 8px;
            transition      : ease 0.3s;
            width           : 32px;
            height          : 32px;
        }
        .profile {
            position : absolute;
            bottom   : 1.8%;
            left     : 21.5%;
        }



    .sideBarMain {
        position        : relative;
        display         : none;
        background-color: color(neutral0);
        width           : 270px;
        transition      : all .3s ease;
        box-shadow      : 1px 0px 0px #DFE1E6;

        .sidebarHeading{
            height: 56px;
            padding: 16px 0px 16px 32px;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: color(neutral100);
        }

        .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : color(neutral40)
        }

        .active .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : color(primary50);
        }
        .bar {
            position      : absolute;
            border-bottom : 0.5px solid #F0F0F0 !important;
            right         : 0%;
            top           : 56px;
            width         : 100%;
        }

        a{
            text-decoration: none !important;
        }
        .item {
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0px 4px 0px 4px;
            border-radius: 4px;
            margin: 0px 8px;
        }

        .items{
            padding: 16px 0px 6px 0px;
        }

        .subItem {
            height: 40px;
            display: flex;
            align-items: center;
            padding-left: 20px;
        }

        .subMenuSelected{
            color: color(neutral100) !important;
            font-weight: 500;
            opacity: 1;
            background-color: color(neutral0) !important;
        }

        .item:hover {
            text-decoration  : none !important;
            opacity: 1;
            border-radius: 4px;
            color: #3770FB !important;

        }
        .item:focus {
            text-decoration  : none !important;
            opacity: 1;
        }
        .active{
            color: #3770FB !important;
            opacity: 1;
            background-color: #ECF4FD;
            border-radius: 4px;
            background : rgba(55, 112, 251, 0.1) !important;
            
        }

        .navlinkFont{
            padding: 8px 12px;
        }

        .active path{
            // fill: color(neutral80);
            opacity: 1;
        }
        
        .filters {
            border-top : 0.5px solid color(neutral20);
        }

        .circle1 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : color(white);
        }
        .circle2 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : color(white);
        }
        .img {
            margin : 8px;
        }
        .circle {
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
        }

        .circle1:hover{
            box-shadow : 0px 4px 8px rgba(133, 133, 133, 0.2);

            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle2:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }

        .walletlyHome {
            margin-top : 32px;
        }
        .Heading2 {
            margin-top : 33px;
        }
        .searchIcon {
            float        : right;
            margin-right : 34px;
        }
    }

    .hideSubNav {
        margin-left : -220px;
        transition  : all .3s ease-in-out;

        .sidebarHeading{
            height: 56px;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : color(neutral40)
        }

        .active .paragraphText {
            font-size   : 14px;
            font-weight : normal;
            color       : color(primary50);
        }
        .bar {
            position      : absolute;
            border-bottom : 1px solid #F0F0F0 !important;
            right         : 0%;
            top           : 59px;
            width         : 100%;
        }

        a{
            text-decoration: none !important;
        }
        .item {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 16px;
        }

        .items{
            padding: 16px 5px 6px 4px;
        }

        .subItem {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 20px;
        }
        .subMenuSelected{
            color: color(neutral100) !important;
            font-weight: 500;
            opacity: 1;
            background-color: color(neutral0) !important;
        }

        .subMenuSelected:hover{
            background-color: color(neutral0) !important;
        }

        .item:hover {
            text-decoration  : none !important;
            opacity: 1;
            background-color : rgba(29, 59, 86, 0.05);
            border-radius: 4px;
        }
        .item:focus {
            text-decoration  : none !important;
            opacity: 1;
            background-color : rgba(29, 59, 86, 0.05);
        }
        .active{
            color: #3770FB;
            opacity: 1;
            background-color: #ECF4FD;
            transition: ease 0.3s;
            border-radius: 4px;
            padding: 8px 12px;
        }

        .active path{
            // fill: color(neutral80);
            opacity: 1;
        }
        
        .filters {
            border-top : 0.5px solid color(neutral20);
        }

        .circle1 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : color(white);
        }
        .circle2 {
            width            : 35px !important;
            height           : 35px !important;
            border-radius    : 35px;
            background-color : color(white);
        }
        .img {
            margin : 8px;
        }
        .circle {
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
        }

        .circle1:hover{
            box-shadow : 0px 4px 8px rgba(133, 133, 133, 0.2);

            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle2:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }
        .circle:hover{
            box-shadow    : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor        : pointer;
            width         : 35px;
            height        : 35px;
            border-radius : 50%;
            transition    : 0.25s ease-in;
        }

        .walletlyHome {
            margin-top : 32px;
        }
        .Heading2 {
            margin-top : 33px;
        }
        .searchIcon {
            float        : right;
            margin-right : 34px;
        }
    }


    .tooglecircle{
       display : none;
    }
}

@media (max-width: 767px){
    #sidebar {
        .tooglecircle{
            display          : inline;
            z-index          : 1;
            width            : 50px;
            height           : 50px;
            border-radius    : 50px;
            position         : fixed;
            bottom           : 7%;
            right            : 10%;
            background-color : color(neutral90);
        }
        .hidePartial { 
            margin-left : -88px;
            transition  : all .6s ease;
        }

        .partailSideNav {
            transition : all .6s ease;

            .brandLogo {
                position : absolute;
                right    : 0%;
                top      : 16px;
                height   : 5.5%;
                left     : 22.3%;
                width    : 54.54%;
            }
            .navPartial {
                position : absolute;
                top      : 83px;
                height   : 62.3%;
                width    : 54.5%;
                // left     : 22.7%;
            }
        }
        .hideSubNav {
            display : none;
        }

        .overlay {
            height     : 100%;
            width      : 100%;
            position   : fixed;
            background : #000000;
            z-index    : 100;
            opacity    : 0.6;
        }
    }
  }


  @media (min-width: 1500px){ 

    .partailSideNav {
        transition : all .4s ease;

        .brandLogo {
            position : absolute;
            right    : 0%;
            top      : 16px;
            height   : 5.5%;
            left     : 22.3%;
            width    : 54.54%;
        }
        .navPartial {
            position : absolute;
            top      : 83px;
            height   : 62.3%;
            width    : 54.5%;
            // left     : 22.7%;
        }
    }
  }

  @media (min-width: 800px) and (max-width: 1300px){ 

    .partailSideNav {
        transition : all .4s ease;

        .brandLogo {
            position : absolute;
            right    : 0%;
            top      : 16px !important;
            height   : 5.5%;
            left     : 22.3%;
            width    : 54.54%;
        }
        .navPartial {
            position : absolute;
            height   : 62.3%;
            width    : 54.5%;
        }
    }

    .sideBarMain{
        .Heading2{
            margin-top : 8% !important;
        }
    }
  }





