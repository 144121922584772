@font-face {
  font-family: balto;
  src: local('Balto Medium'), local('Balto-Medium'),
    url('./fonts/Balto-Medium.woff2') format('woff2'),
    url('./fonts/Balto-Medium.woff') format('woff'),
    url('./fonts/Balto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


#inprogress {
  width: 100%;
  // .ani {
  //   animation-name: example;
  //   animation-duration: 3s;
  // }
  
  // @keyframes example {
  //   from {height: 449.5px;}
  //   to {height: 0px;}
  // }
  @keyframes fade-in {
    0% {
      opacity: 0;
      transform: translateY(10px); /* Optional: Apply a slight vertical translation */
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation to the element */
  .animat {
    animation: fade-in 3s ease-in-out; /* Adjust the duration and easing as needed */
  }

  .topSectionSlider {
    width: 100%;
    height: 245px;
  }
  .Sli-top-main{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .Sli-top-images{
    width: 100%;
    height: 100%;
  }
  .Sli-top-buttons{
    position:absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    top: 50%;
  }
  .Sli-top-btnOne{
    width: 30px;
    height: 30px;
    background-color:white;
    border-radius: 50%;
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-left: 5px;
  }
  .Sli-top-btnTwo{
    width: 30px;
    height: 30px;
    background-color:white;
    border-radius: 50%;
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-right: 5px;

  }
  

  .slider-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
  }

  .slider-info {
    padding: 20px;
    width: 100%;

  }

  .slider-info h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    /* 150% */
  }

  // .slider-info p {
  //   color: #575A60;
  //   font-family: Inter;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 25px;
  // }

  .firstChild {
    width: 90%;
  }

  .slide-container {
    width: 100%;
    margin: auto;
  }

  .slide-containerS {
    width: 100%;
    margin: auto;
  }

  .each-slide>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 300px;
  }

  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  li {
    font-size: 18px;
  }

  .each-fade {
    display: flex;
  }

  .slider_button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }

  .each-fade .image-container img {
    width: 100%;
  }

  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .indicator {
    width: 30px;
    color: blue;
    text-align: center;
    cursor: pointer;
    border: 1px blue solid;
  }

  .indicator.active {
    background: #000;
    color: #fff;
  }


  .logoImg {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .lastImage img {
    // width: 38.75vw;
    width: 100%;
    object-fit: contain;
  }

  .singleColorBox {
    width: 36px;
    height: 36px;
    border-radius: 2px;
  }

  .large-heading {
    text-align: left !important;
    color: #000;
    font-family: Balto;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0.84px;
    width: 100%;
    height: auto;
    word-wrap: break-word;
  }

  .large-heading span {
    color: #3C7DF6 !important;
  }

  .B-color {
    color: #3C7DF6 !important;
    font-weight: 600;
  }

  .large-heading-spe {
    color: #000;
    font-family: Balto;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.72px;
    width: 100%;
    text-align: left;
  }

  .Headingthree {
    color: #000;
    font-family: Balto;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.68px;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 20px;
    width: 100%;
    word-wrap: break-word;
  }

  .againpara {
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.6px;
    width: 100%;
    height: 'auto';
    word-wrap: break-word;
  }

  .para {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #575A60;
    // width: 670px;
    width: 95%;
    margin-top: 15px;
  }

  .para-2 {
    font-weight: 400;
    font-size: 2.90vw;
    line-height: 3.46875vw;
    margin-top: 2.25vw;
    margin-bottom: 2.25vw;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-style: normal;
  }

  .firstChild {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cp {
    cursor: pointer;
  }


  .join-button {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    width: 309px;
    height: 56px;
    background: #2072ED;
    border-radius: 7px;
    margin-top: 30px;
    border: none;
    color: #F2F6FF;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  .join-button-spe .MuiButton-label div {
    width: 100% !important;
  }

  .join-button-spe {
    display: flex;
    height: 44px !important;
    width: 100%;
    padding: 12px 24px;

    gap: 8px;
    background-color: #000 !important;
    color: #FFF;
    font-family: Inter !important;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0.1px;
    border-radius: 10px !important;
    margin-top: 60px;
  }

  .secondChild {
    width: 100%;
    display: flex;
    margin-top: 48px;
    justify-content: center;
  }

  .secondChild img {
    width: 100%;

  }


  .subthirdchild {
    // width: 87%;
    display: flex;
    justify-content: center;
    // flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    align-items: center;

  }

  .para2 {
    font-weight: 400;
    font-size: 1.40625vw;
    line-height: 2.34375vw;
    color: #575A60;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
    width: 670px;

  }

  .lastImage2 {
    width: 70%;
    display: none;
  }

  .lastImage2 img {
    width: 100%;
  }

  .SubMain {
    width: 430px;
    margin: 25px auto;
    border-radius: 20px;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .inprogress {
    // width: 800px;
    width: 430px;
    margin: 25px auto;
    border-radius: 20px;
    background: #F2F2F2;
    // margin-top: 60px;
    // margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .banner-image {
    width: 100%;
    height: 100%;
    // box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.25);
  }

  .each-slide {
    height: 245px;
  }

  .thirdChild {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 60px;
    // height: 263px;
  }

  .lastImage {
    width: 100%;
  }

  .lastDatils {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ListW {
    color: #575A60;
    margin: 0;
  }

  ul {
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
  }


  .vipLi {
    list-style: none;
    width: 100%;
    display: flex;
    // justify-content: flex-start;
    // align-items: center;

  }

  .nav,
  .default-nav {
    margin: 10px;
    width: 25px;
    height: 25px;
  }

  .default-nav svg {
    height: 50% !important;
    margin-left: 6px;
  }

  svg {
    margin: 0;
  }

  .joinbtnfirstMain {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  .firstJoinBtn {
    display: flex;
    width: 165px;
    height: 44px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    background-color: #000;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;

  }

  @media only screen and (max-width: 655px) {
    .SubMain {
      width: 100%;
      margin: 0px;
      border-radius: 0px;
    }

    .firstChild {
      align-items: flex-start;
    }

    .large-heading {
      text-align: left !important;
      color: #000;
      font-family: Balto;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 45px;
      /* 107.143% */
      letter-spacing: 0.84px;
    }

    .logoImg {
      margin-bottom: 30px;
    }

    .Mob {
      height: 200px !important;
    }

    .lazy {
      height: 100%;
    }

    .againpara {
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.6px;
    }

    .joinbtnfirstMain {
      width: 100%;
      margin: 0 auto;
    }




    .logoImg {
      width: 80px;
      height: auto;
    }

    .lastDatils {
      width: 100%;
    }

    .lastImage {
      width: 100%;
    }

    .lastImage img {
      width: 100%;
    }



    .large-heading-spe {
      font-weight: 800;
      font-size: 26px;
      line-height: 41px;
    }

    .para {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      font-family: "Poppins", sans-serif;
      color: #575A60;
      text-align: center;
    }

    .para2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }



    .subthirdchild {
      width: 90%;
    }
  }

  @media only screen and (max-width: 390px) {
    .logoImg {
      margin-top: 50px;
    }

    .secondChild {
      width: 100%;
    }

    .thirdChild {
      margin-top: 40px;
    }

    .secondChild {
      padding: 0px !important;
    }

    .join-button {
      margin-top: 10px;
    }

    .para {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    .againpara {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

    .banner-image {
      // width: 95%;
    }


    .large-heading-spe {
      font-size: 22px;
    }

    .ListW {
      font-size: 16px;
    }
  }




}

#VipClub {
  width: 100%;
  transition: all 3s ease-in-out;
  .animat {
    animation-name: example;
    animation-duration: 3s;
  }
  
  @keyframes example {
    from {height: 449.5px;}
    to {height: 0px;}
  }
  .topSectionSlider {
    width: 100%;
    height: 458px;
  }
  .Sli-top-main{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .Sli-top-images{
    width: 100%;
    height: 100%;
  }
  .Sli-top-buttons{
    position:absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    top: 50%;

  }
  .Sli-top-btnOne{
    width: 30px;
    height: 30px;
    background-color:white;
    border-radius: 50%;
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-left: 5px;
  }
  .Sli-top-btnTwo{
    width: 30px;
    height: 30px;
    background-color:white;
    border-radius: 50%;
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-right: 5px;

  }
  


  .Headingthree {
    color: #000;
    font-family: Balto;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.68px;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  // margin: 20px auto;
  .slider-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.10);
  }

  .slider-info {
    padding: 20px;
    width: 100%;
  }

  .slider-info h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 150% */
  }
  .card-upper-heading{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // .slider-info p {
  //   color: #575A60;
  //   font-family: Inter;
  //   font-size: 18px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 25px;
  // }

  .firstChild {
    width: 90%;
  }

  .slide-container {
    width: 100%;
    margin: auto;
  }

  .slide-containerS {
    width: 100%;
    margin: auto;
  }


  .each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  li {
    font-size: 18px;
  }

  .each-fade {
    display: flex;
  }

  .slider_button {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .each-fade .image-container {
    width: 75%;
    overflow: hidden;
  }

  .each-fade .image-container img {
    width: 100%;
  }

  .each-fade h2 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }

  .indicator {
    width: 30px;
    color: blue;
    text-align: center;
    cursor: pointer;
    border: 1px blue solid;
  }

  .indicator.active {
    background: #000;
    color: #fff;
  }


  .logoImg {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .lastImage img {
    // width: 38.75vw;
    width: 100%;
    object-fit: contain;
  }

  .singleColorBox {
    width: 36px;
    height: 36px;
    border-radius: 2px;
  }

  .large-heading {

    color: #000;
    text-align: center;
    font-family: balto, sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px;
    /* 110% */
    letter-spacing: 1px;
  }

  .large-heading span {
    color: #3C7DF6 !important;
  }

  .B-color {
    color: #3C7DF6 !important;
    font-weight: 600;
  }

  .large-heading-spe {
    color: #000;
    text-align: center;
    font-family: balto, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 1px;
    text-align: left;
    width: 100%;
  }

  .againpara {

    color: #575A60;
    text-align: center;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.75px;
    margin-top: 10px;
  }

  .para {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #575A60;
    // width: 670px;
    width: 95%;
    margin-top: 15px;
  }

  .para-2 {
    font-weight: 400;
    font-size: 2.90vw;
    line-height: 3.46875vw;
    margin-top: 2.25vw;
    margin-bottom: 2.25vw;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-style: normal;
  }

  .firstChild {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cp {
    cursor: pointer;
  }


  .join-button {
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    width: 309px;
    height: 56px;
    background: #2072ED;
    border-radius: 7px;
    margin-top: 30px;
    border: none;
    color: #F2F6FF;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  .join-button-spe .MuiButton-label div {
    width: 100% !important;
  }

  .join-button-spe {
    display: flex;
    height: 44px;
    width: 100%;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    background-color: #000 !important;
    color: #FFF;
    font-family: Inter;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 20px !important;
    letter-spacing: 0.1px;
    border-radius: 10px !important;
    margin-top: 60px;
  }

  .secondChild {
    width: 100%;
    display: flex;
    margin-top: 48px;
    justify-content: center;
  }

  .secondChild img {
    width: 100%;

  }


  .subthirdchild {
    // width: 87%;
    display: flex;
    justify-content: center;
    // flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    align-items: center;

  }

  .para2 {
    font-weight: 400;
    font-size: 1.40625vw;
    line-height: 2.34375vw;
    color: #575A60;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
    width: 670px;

  }

  .lastImage2 {
    width: 70%;
    display: none;
  }

  .lastImage2 img {
    width: 100%;
  }

  .SubMain {
    // width: 800px;
    width: 653px;
    margin: 25px auto;
    border-radius: 20px;
    background: #F2F2F2;
    // margin-top: 60px;
    // margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .inprogress {
    // width: 800px;
    width: 430px;
    margin: 25px auto;
    border-radius: 20px;
    background: #F2F2F2;
    // margin-top: 60px;
    // margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .banner-image {
    height: 458px;
    object-fit: cover;
    width: 653px;
  }

  .each-slide {
    height: 458px;
    
  }

  .thirdChild {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 90%;
    margin-top: 80px;
    // height: 263px;
  }

  .lastImage {
    width: 100%;
  }

  .lastDatils {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ListW {
    color: #575A60;
    margin: 0;
  }

  ul {
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  ol {
    // padding: 10px;
  }

  .vipLi {
    list-style: none;
    width: 100%;
    display: flex;
    // justify-content: flex-start;
    // align-items: center;

  }

  .nav,
  .default-nav {
    margin: 10px;
    width: 25px;
    height: 25px;
  }

  .default-nav svg {
    height: 50% !important;
    margin-left: 6px;
  }

  svg {
    margin: 0;
  }

  // @media only screen and (max-width: 750px) {
  //   .SubMain {
  //     width: 90%;
  //   }

  //   .logoImg {
  //     margin-bottom: 30px;
  //   }

  //   .Mob {
  //     height: 200px !important;
  //   }

  //   .lazy {
  //     height: 100%;
  //   }

  //   .para,
  //   .para2,
  //   .againpara {
  //     width: 90%;
  //   }



  //   .logoImg {
  //     width: 80px;
  //     height: auto;
  //   }

  //   .lastDatils {
  //     width: 100%;
  //   }

  //   .lastImage {
  //     margin-top: 40px;
  //     width: 100%;
  //   }

  //   .lastImage img {
  //     width: 100%;
  //   }

  //   .large-heading {
  //     font-style: normal;
  //     font-weight: 800;
  //     font-size: 32px;
  //     line-height: 39px;
  //     text-align: center;
  //     text-align: center;
  //     font-family: "Poppins", sans-serif;
  //     /* margin-top : 2.25vw; */
  //     width: 95%;

  //   }

  //   .large-heading-spe {
  //     font-weight: 800;
  //     font-size: 26px;
  //     line-height: 41px;
  //   }

  //   .para {
  //     font-family: 'Inter';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 20px;
  //     line-height: 30px;
  //     font-family: "Poppins", sans-serif;
  //     color: #575A60;
  //     text-align: center;
  //   }

  //   .para2 {
  //     font-weight: 400;
  //     font-size: 18px;
  //     line-height: 30px;
  //   }

  //   .join-button-spe {
  //     margin-left: auto;
  //   }

  //   .subthirdchild {
  //     width: 90%;
  //   }
  // }
  .joinbtnfirstMain {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;

  }

  .firstJoinBtn {
    display: flex;
    width: 165px;
    height: 44px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    background-color: #000;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
  }


  @media only screen and (max-width: 655px) {
    .SubMain {
      width: 100%;
      margin: 0px;
      border-radius: 0px;
    }

   
    .each-slide{
      height: 245px;

    }
    .banner-image {
      height: 245px;
      width: 100%;
    }
    .topSectionSlider {
      width: 100%;
      height: 245px;
    }


    .firstChild {
      align-items: flex-start;
    }

    .large-heading {
      text-align: left !important;
      color: #000;
      font-family: Balto;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 45px;
      /* 107.143% */
      letter-spacing: 0.84px;
    }

    .logoImg {
      margin-bottom: 30px;
    }

    .Mob {
      height: 200px !important;
    }

    .lazy {
      height: 100%;
    }

    .againpara {
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.6px;
    }

    .joinbtnfirstMain {
      width: 90%;
      margin: 0 auto;
      justify-content: flex-start;
      margin-bottom: 60px;
    }




    .logoImg {
      width: 80px;
      height: auto;
    }

    .lastDatils {
      width: 100%;
    }

    .lastImage {
      width: 100%;
    }

    .lastImage img {
      width: 100%;
    }



    .large-heading-spe {
      font-weight: 800;
      font-size: 26px;
      line-height: 41px;
    }

    .para {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      font-family: "Poppins", sans-serif;
      color: #575A60;
      text-align: center;
    }

    .para2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    .join-button-spe {
      margin-left: auto;
    }

    .subthirdchild {
      width: 90%;
    }
  }

  @media only screen and (max-width: 390px) {
    .logoImg {
      margin-top: 50px;
    }

    .secondChild {
      width: 100%;
    }

    .thirdChild {
      margin-top: 40px;
    }

    .secondChild {
      padding: 0px !important;
    }

    .join-button {
      margin-top: 10px;
    }

    .para {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    .againpara {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
    }

  


    .large-heading-spe {
      font-size: 22px;
    }

    .ListW {
      font-size: 16px;
    }
  }




}