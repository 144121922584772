#TemplateDesign{
    background-color: color(neutral0);
    position: relative;
    width: 100%;

    .saveBtn {
        position: fixed;
        top: 10px;
        left: 63.5%;
    }

    .backPage {
        position: fixed;
        top: 10px;
        left: 50%;
    }

    .viewandDownloadButtons{
        position: fixed;
        top: 10px;
        left: 20%;
    }

    .widgetTitle{
        position: fixed;
        top: 12px;
        left: 40%;
    }

    .editIcon{
        vertical-align: text-bottom;
    }

    .full-height{
        height: calc(100vh - 72px);
    }

    .element-referer{
        width: fit-content;
        background: color(primary50);
        color: white;
        padding: 4px 8px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        position: absolute;
        font-size: 10px;
    }
}