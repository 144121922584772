#agencyInvite{
    height: 100vh;

    .cardBox{
        height: inherit;
    }

    .card{
        width: 60%;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0px 0px 16px rgba(148, 148, 184, 0.15);
        border-radius: 4px;
        padding: 32px 64px;
        min-height: 500px;
    }
    

    .cardHeader{
        display: flex;
        justify-content: center;
    }

    .brandIcon{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: black;
    }

    .ConfirmationText{
        font-size: 18px;
        font-weight: 480;
        color: color(white);
        line-height: 30px;
        letter-spacing: 0.1;
        text-align: center;
    }
    
    .description{
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: color(neutral60);
        max-height: 70%;
        overflow-y: scroll;
    }

    .lowerButtons{
        margin-top: 42%;
        display: flex;
        justify-content: space-between;
    }
}