$sizeamount: (
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    20,
    100,
); // Adjust this to include the pixel amounts you need.

@each $size in $sizeamount {
    .z-index-#{$size} {
        z-index: #{$size} !important;
    }
}

