.wmb-main-container {
    width: 100%;
    height: 100vh;
    background: linear-gradient(248.71deg, #7EE8FA -22.18%, #EEC0C6 100%);
    // background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-liness {

    width: 100%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    margin-top: 25px;
    line-height: 24px;
}

.carosel {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    overflow-x: scroll;
}

.namess {
    display: flex;
    // width: 100%;
    // gap: 3px;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 16px;
}

.email-field {

    display: flex;
    // flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin-bottom: 16px;
}

.allForm {
    margin-top: -30px;
    width: 100%;
}

.code {

    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: center;
    margin-bottom: 16px;

}

.second-join-btn {
    justify-content: center;
    align-items: center;
}

.signIn-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 24px;
    // justify-content:flex-start;
    margin-bottom: 16px;
    margin-top: 50px;
    // width: 100%;

    margin-bottom: 16px;
}

// .sign-in-form{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 100%;

// }

.final-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
}

// Fourth Business Card Email Verification

// .card{
//     padding: 24px 40px;
//     width:100%;
//     margin:auto;
//     height: 100%;
// }
.my-cardForm {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

}

.last-form {
    width: 723px;
}

.Heading2 {
    color: color(neutral80);
}

.subtitle1 {
    color: color(neutral70),
}

.numberInput {
    text-align: center;
    height: 120px;
    padding: 0px;
    font-size: 50px;
    color: color(neutral80);
    border: none;
    border-bottom: 5px solid color(neutral70);
    width: 20%;
}

.inputFields {
    display: flex;
    // flex-direction: row;
    width: 90%;
    justify-content: space-between;
}

.verifyButton {
    background-color: color(primary50);
    height: 52px;
    width: 100%;
    border-radius: 8px;
    padding: 12px, 16px, 12px, 16px;
    font-size: 14px;
}

.resendLogout {
    color: color(primary50);
}

//verification code css

.code-label {
    margin-bottom: 16px;
}

.code-inputs {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 18px;


}

.code-inputs input {
    text-align: center;
    // height: 120px;
    padding: 0px;
    font-size: 30px;
    color: color(neutral80);
    border: none;
    border-bottom: 5px solid color(neutral70);
    width: 20%;
}

.code-inputs input:focus {
    outline: none;
}

.myMiddle {
    padding-left: 30px;
    padding-right: 30px;

}

.mail {
    margin-top: 40px;
}

.inputfields {
    width: 100%;
}

.eachImage {
    // width: 220px;
    height: 250px;
    margin-bottom: 16px;
    border-radius: 4px;
    // border: 1px solid black;
}


.codee {
    margin-top: 40px;
    // margin-left: 25px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.copy-cw {
    margin-bottom: 70px;
    margin-top: 50px;
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.eachImage>image {
    border-radius: 4px;
}

.each-qr {
    transition: 0.2s ease-in;
    width: 220px;
    height: 269px;
    margin-bottom: 16px;

}

.for-qr {

    justify-content: space-between;
}

.for-qr-back {
    gap: 20px;
    justify-content: center;
}

.qr-btn {
    cursor: pointer;
}

.back-btn {
    cursor: pointer;
}

// .first-page-profile {
//     margin-top: -10px;
// }

.main-cont-signin {
    // height: vh;
    width: 600px;

    // border-radius: 6px;
    //  overflow-y : scroll;
}

.main-cont-signin-4 {
    // height: 90vh;
    width: 600px;
}



.f1-div {

    border-radius: 20px 20px 0px 0px;
}

.f1 {
    border-radius: 20px 20px 0px 0px;
}

@media only screen and (min-width: 1350px) {

    .big-screen {

        height: 220px;
    }

}

.main-cont-signin {
    overflow-y: auto;
}

@media only screen and (max-width: 1280px) {


    // .namess {

    //     margin-top: 50px;

    // }

    .allForm {

        width: 100%;
        margin-top: -40px;
    }

    .main-cont-signin {

        height: 100vh;
    }

    .main-cont-signin-4 {
        // height: 95vh;
        overflow-y: auto;
        width: 600px;
    }

    .scroll-items {
        height: 180px;

    }

    .last-div {
        padding-left: 30px
    }


}

@media only screen and (max-width: 600px) {


    .profile-pic {
        width: 100px !important;
        height: 100px !important;
    }

    .first-page {
        // display: flex !important;
        // flex-wrap: wrap !important;
        // flex-direction: column !important;

    }

    .brand-name {
        font-size: 20px !important;
    }

    .brand-service {
        font-size: 14px !important;
    }


    .verify-mail {
        gap: 30px;
    }

    #signup-form-cover {
        width: 100%;
    }

    .main-cont-signin {
        height: 100vh;
        width: 100%;
        //  overflow-y : scroll;
    }

    .main-cont-signin-4 {

        width: 100%;
        //  overflow-y : scroll;
    }

    .cover-up {
        width: 100%;
    }

    // .cover-up>img {
    //     width: 100%;
    // }

}