#Redemption{
.addredemptionbtn{
        color: color(neutral100);
        font-size: 14px;
        width: fit-content;
        height: 45px;
        text-align: center;
        padding: 12px 16px 12px 16px;
}


}
