@for $i from 0 through 100 {
   .fs-#{$i} {
    font-size: #{$i}px !important;
  }
  .fs-icon-#{$i} {
    font-size: #{$i}px !important;
  }
  .fw-#{$i} {
    font-weight: calc(#{$i} * 100) !important;
  }
}
