#SignupPopUp{
        .apiError{
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
            color: red;
        }
        .body{
            width: 100%;
            height: 100vh;
            overflow: auto;
        }
    
        .body, .block{
            display: flex;
            flex-direction: column;
        }
    
        .submit{
            width: 36px !important;
            padding: 6px 0 !important;
            border-radius: 50px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    
    
        .loaders {
            overflow: hidden;
            width: 100%;
            height: 100%;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
            background-color: inherit;
            border-radius: 50px;
        }
    
        .loader {
            min-width: 100px;
        }
    
        /* circle loaders */
    
        .circle-loader-1 {
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            border-radius: 50%;
            border-top: 3px solid currentColor;
            border-right: 3px solid transparent;
            border-bottom: 3px solid currentColor;
            border-left: 3px solid currentColor;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-animation: circle-loader-spin 1s infinite linear;
            animation: circle-loader-spin .5s infinite linear;
        }
    
    
        @keyframes circle-loader-spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
    
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
    }