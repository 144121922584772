/* Styling for the Quill Editor container */
.ql-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  /* Styling for the Quill Editor toolbar */
  .ql-toolbar {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  /* Styling for the Quill Editor editable area */
  .ql-editor {
    min-height: 200px;
    line-height: 1.6;
  }
  
  /* Styling for various formatting options */
  .ql-bold {
    font-weight: bold;
  }
  
  .ql-italic {
    font-style: italic;
  }
  
  .ql-underline {
    text-decoration: underline;
  }
  
  /* Add more classes for other formatting options as needed */
  
  /* Styling for links */
  .ql-link {
    color: blue;
    text-decoration: underline;
  }
  
  .ql-link:hover {
    color: #0056b3;
  }
  
  /* Styling for blockquote */
  .ql-blockquote {
    border-left: 4px solid #ccc;
    padding-left: 10px;
    margin-left: 0;
  }
  
  /* Styling for headers */
  .ql-header {
    font-size: 18px;
    font-weight: bold;
  }
  
  /* Styling for lists */
  .ql-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  /* Styling for images */
  .ql-image {
    max-width: 100%;
    height: auto;
  }
  
  /* Styling for videos */
  .ql-video {
    max-width: 100%;
  }
  
  /* Styling for formulas */
  .ql-formula {
    font-style: italic;
  }
  
  /* Styling for text alignment */
  .ql-align-left {
    text-align: left;
  }
  
  .ql-align-center {
    text-align: center;
  }
  
  .ql-align-right {
    text-align: right;
  }
  
  .ql-align-justify {
    text-align: justify;
  }
  
  /* Styling for colors */
  .ql-color-picker {
    width: 28px;
    height: 28px;
  }
  
  /* Styling for background colors */
  .ql-background-picker {
    width: 28px;
    height: 28px;
  }
  
  /* Styling for fonts */
  .ql-font {
    font-family: Arial, sans-serif;
  }
  
  /* Styling for font sizes */
  .ql-size {
    font-size: 14px;
  }
  .ql-font-serif {
    font-family: Georgia, serif;
  }
  
  /* Styling for font sizes */
  .ql-size-huge {
    font-size: 24px !important;
  }  
  .ql-font-serif {
    font-family: Georgia, serif;
  }
  
  .ql-font-monospace {
    font-family: "Courier New", monospace;
  }
  .ql-size-small {
    font-size: 12px;
  }
  
  .ql-size-medium {
    font-size: 16px;
  }
  .ql-size-large {
    font-size: 20px;
  }    