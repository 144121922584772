#customPass{
    position: relative;
    .singleCard{
        width: 221px;
        height: 348px;
        box-sizing: border-box;
        border: 0.58459px solid #E0E0E0;
        border-radius: 9.33779px;
        padding-bottom: 16px;
    }

    .cardTop{
        padding: 8px;
    }

    .cardLogo{
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    .cardCover{
        max-height: 87px;
        max-width: 219px;
    }

    .cardContent{
        padding: 8px;
    }

    .hoverShow{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        justify-content: center;
        font-weight: 600;
        width: 221px;
        height: 348px;
        box-sizing: border-box;
        border: 0.58459px solid #E0E0E0;
        border-radius: 9.33779px;
        padding-bottom: 16px;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .actionButtons{
        justify-content: center;
    }

    .buttonBackground{
        width: 33px;
        height: 33px;
        border-radius: 40px;
        background-color: rgba(255, 255, 255, 0.4);
        text-align: center;
        padding: 3px;
    }

    .hoverText{
        justify-content: center;
        padding-top: 60%;
        font-size: 16px;
    }

}