

.OnBoardingPage{


    .animationComponent{
        // animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        // animation-duration: 150ms;
        animation: fadeInAnimation cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        // transition-timing-function: ease;
        // transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 2);
    }

        @keyframes fadeInAnimation {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

    #brandNameScreen, #welcomeScreen{
        padding: 40px 200px 0px 200px;

        .loyaltyTypeBox{
            background   : #FFFFFF;
            border       : 1px solid #E6E6E6;
            box-sizing   : border-box;
            border-radius: 6px;
            width        : 32%;
            padding      : 24px;
            cursor       : pointer;
        }
    }

    .header {
        width: 100%;
        position: fixed;
        z-index: 9999;
        top: 0px;
        height: 70px;
        background-color: white;
    }

    .bar{
        background: linear-gradient(90deg, color(primary60) 0%, #5F9EF1 100%);  
        z-index: 1;
        border-radius: 16px;
        -webkit-transition : width 0.7s ease;
        -moz-transition : width 0.7s ease;
          -o-transition : width 0.7s ease;
             transition : width 0.7s ease;
    }

    .btn-next {
        float: right;
        border-radius: 4px;
    }

    #logo {
        border-radius: 50%;

    }
}

.progressBar{
    width : 50%;
    height : 8px;
    background-color: lightgray;
    border-radius: 16px;
}

.bottomBarBox{
    width : 400px;
    align-items: center;
    // justify-content: space-between;
}



#connectBrand{ 
    width: 100%;
    position: absolute;
    background-color: color(white);
    padding: 0;
    z-index: 999;
    transition: transform 0.8s;

    .cardMain {
        width: 80%;
        margin: auto;
    }

    .cardsRow {
        width: 80%;
        display: flex;
        flex-direction: row;
    }

    .cardsSel {
        position: relative;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 8px;
        width: 251px;
        height: 250px;
        margin: auto;
        border: 1px solid #747474;
    }

    .coverimage{
        width: 100%;
        background-color:lightgray;
        position :absolute;
        top: 0px;
        height: 100px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        overflow: hidden;
    }

    .cards {
        position: relative;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        border-radius: 8px;
        width: 250px;
        height: 250px;
        margin: auto;
    }

    .circle {
        width: 80px;
        height: 80px;
        margin: auto;
        background-color: color(neutral0);
        border-radius: 50%;
        position: relative;
    }

    .selected {
        position: absolute;
        top : 8px;
        padding: 4px 6.5px;
        border-radius: 50%;
        right: 8px;
    }

    .toolbar {
        display: none;
    }

    .subHeader{
        display: none;
    }
    .brands{
        display: none;
    }

    .input {    
        width: 100px;
        height: 25px;
        margin: auto;
        position: absolute;
        top: 155px;
        left: 76px;
        text-align: center;
    }


}

.scale {
    transform: translate(35%, 76px);
    background: none;
    z-index: -1 !important;
    overflow-x: hidden;
    
    &.map{
        transform: translate(55%, 76px);
        z-index: 999 !important;
    }

    .subHeader{

        width: 97%;
        margin: auto;
        display: block !important;
    }
    .brands {
        margin-left: 30px !important;
        display: block !important;
    }

    .cardsRow {
        width: 70% !important; 
        margin-top: 10px !important;
        margin: 0px;
    }

    .Heading2 {
        display: none;
    }
    .onBordingFirstFont {
        display: none;
    }
    .Heading4 {
        display: none;
    }
    .col {
        height: 80vh;
        text-align: left !important;
        padding-right: 0px;
        border-radius: 24px;
        padding-left: 0px;
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        transition: box-shadow 0.7s ease-in-out;
    }

    .toolbar {
        display: flex !important;
        flex-direction: row;
        border-radius: 24px 0px 0px 0px;
        height: 40px;
        width: 100%;
        background-color: #E9ECEF;
        grid-gap: 0px;
    }
  
    .circleTool {
        width: 16px;
        height: 16px;
        background-color: #9DA7AE;
        border-radius: 50%;
        margin-left: 20px;
        margin-top: 10px;
    }
}

#brandNameScreen{
    width: 100%;
    padding: none;

    .makeStyles-root-3{
        padding: 0;
    }  

    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        width: 65%;
    }

    .customInputField{
        width: 100%;
    }
    
    //inputfield

    .MuiInputBase-input MuiInput-input makeStyles-root-6{
        padding: 0;
    }

    .makeStyles-root-6 {
        padding: 0px !important;
        padding-bottom: 10px !important;
    }

        .caption{
            color: #000;
        }

        
        .Heading22R{
            line-height: 48px;
            font-weight: 300;
        }


        .singleServiceType{
            height: 40px;
            min-width: 100%;
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            border-radius: 8px;
            border: 1px solid color(neutral100);
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            color: color(neutral60);
            background-color: white;

        }

        .singleServiceType:hover{
            border: 1px solid color(primary50);
            color: color(primary50) ;
        }

        .active{
            border: 1px solid color(primary50);
            color: color(primary50) ;
        }




}


#LoyaltySelectionScreen{
    width     : 100%;
    height    : 100vh;
    padding   : none;
    overflow-y: hidden;

    .leftBox{
        padding: 24px 16px;
        // width: 30%;
        width: 30vw;
        box-shadow: 1px 0px 0px #E5E5E5;
        background-color: white;
        height: 100vh;
        overflow-y: scroll;
        position: fixed;
        left: 0px;
    }

    .loyaltyTypeBox{
        background: #FFFFFF;
        box-shadow: 0px 6px 16px rgba(47, 128, 237, 0.16);
        border-radius: 6px;
        padding: 16px;
        width:  100%;
        outline: 1px solid color(neutral10);
    }

    .logoPreviewBox{
        background   : #FFFFFF;
        box-shadow   : 0px 8px 20px rgba(4, 24, 62, 0.08);
        border-radius: 8px;
        padding      : 24px 16px;
        // max-height   : 100%;
        height       : 80%;
    }

    .rightSideBox{
        width   : 70vw;
        position: fixed;
        right   : 0px;
        padding : 24px 48px;
        height  : 100%;

    }

    .activeCheckout{
        outline: 2px solid #2f80ed;
        background-color: #E8EFF7;
    }

}

#brandLogoScreen{ 
    width     : 100%;
    padding   : none;
    overflow-y: scroll;

    .leftBox{
        padding: 24px 16px;
        // width: 30%;
        width: 30vw;
        box-shadow: 1px 0px 0px #E5E5E5;
        background-color: white;
        height: 100vh;
        overflow-y: scroll;
        position: fixed;
        left: 0px;
    }


    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        height: 100%;
        width: 75%;

    }
    

    .contents{
        display: flex;
        flex-direction: row;
    }

    .singleLoyaltyType{
        padding: 10px 12px;
    }

    .servicesOuterBox{
        box-shadow: -4.08333px -4.08333px 12.25px #F0F1F5, 6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4), inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);
        width: 100%;
        margin-top: 16px;
        z-index: 1000;
    }

    .selectDropdown{
        border-radius: 4px;
        color: rgb(9, 30, 66);
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(226, 232, 240);
        font-size: 14px;
        padding: 8px 12px 8px 12px;
        width: 100%;
        height: 33px;
    }

    .singleLoyaltyType:hover{
        background: #E8EFF7;
    }

    .activeCheckout{
        background: #E8EFF7;
    }

    .activeCheckout:hover{
        background: #E8EFF7;
    }

    .expandable{
        transition: all .3s ease-in-out;
        height: 0;
        overflow: hidden;
    }

    .expand{
        height    : 250px;
        max-height: 300px;
    }

    .dropImageCircle{
        width: 100%;
        height: 152px;
        margin: auto;
        border: 1px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 4px;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity: 1;
            }
            
        }
    }

    .imageUpload{
        transition: opacity ease .7s;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        width: calc(100% - 32px);
        height: inherit;
        position: absolute;
        z-index: -1;
        object-fit: cover;
        border-radius: 4px;
    }

    .box1{
        justify-content: center;
        width: 35%;
        border-right: 1px solid #F0F0F0;
    }

    .box2{
        width: 65%;
        text-align: center;
    }

    .caption{
        color: #000;
    }

    .circleRow{
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
        padding-bottom: 7%;
    }

    .colorCircle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .selectedColor{
        transform: scale(1.4);
        transition: ease 0.3s;
    }

    .nextBtn{
        background-color: color(primary50);
        color: white;
        width: 40%;
        font-size: 14px;
        height: 80%;
    }
    
    .pallette{
        width: 237px !important;
    }

    .logoPreviewBox{
        background   : #FFFFFF;
        box-shadow   : 0px 8px 20px rgba(4, 24, 62, 0.08);
        border-radius: 8px;
        padding      : 24px 16px;
        max-height   : 100%;
        overflow-y   : scroll;
    }

    .rightSideBox{
        width   : 70vw;
        position: fixed;
        right   : 0px;
        padding : 24px 48px;
        height  : 94%;

    }

    .previewCoverBox{
        z-index: 1;
    }

    .previewCover{
        border-radius: 6px;
        height: 350px;
        width: 100%;
        z-index: -1;
        object-fit: cover;
    }

    .logoBoxPreview{
        margin-top: -20px;
        z-index: 2;
    }

    .previewLogo{
        height: 140px;
        width: 140px;
        border-radius: 50%;
        border: 3px solid #FFFFFF;
        z-index: 1;

    }

    .brandDetailSection{
        line-height: 18px;
        word-break: break-word;
    }

    .mapOuterBox{
        background-color: color(neutral0);
        padding: 16px 24px;
    }

    .mapOverlay{
        position  : absolute;
        top       : 0px;
        left      : 0px;
        z-index   : 4;
        height    : 100%;
        width     : 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .insideMapBox{
        padding: 24px 16px;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0px 4px 20px rgba(4, 24, 62, 0.05);
    }

    .mapTopDetailsBox{
        padding: 10px 8px;
        
    }

}


#brandCoverScreen{
    width: 100%;
    padding: none;
    z-index: 999;

    .cards{
        margin: auto;
        margin-top: 20%;
        margin-left: 20%;
        height: 100%;
        width: 75%;

    }

    .contents{
        display: flex;
        flex-direction: row;
    }

    .dropImageCircle{
        width: 100%;
        height: 152px;
        margin: auto;
        // background-repeat: no-repeat;
        // background-size: cover;
        border: 1px dashed color(neutral70);
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: background-color ease .7s;
        &:hover{
            &.image{
                background-color: rgba(0,0,0,.6);
            }
            .imageUpload{
                opacity: 1;
            }
        }
    }

    .imageUpload{
        transition: opacity ease .7s;
    }

    .imageUpload.remove{
        opacity: 0;
    }

    .insideImage{
        width: 400;
        height: inherit;
        position: absolute;
        z-index: -1;
        top: 0;
        object-fit: cover;
        left: 0;
    }

    .box1{
        justify-content: center;
        width: 35%;
        border-right: 1px solid #F0F0F0;
    }

    .box2{
        width: 65%;
        text-align: center;
    }

    .caption{
        color: #000;
    }

    .circleRow{
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
        padding-bottom: 7%;
    }

    .colorCircle{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .selectedColor{
        transform: scale(1.4);
        transition: ease 0.3s;
    }

    .nextBtn{
        background-color: color(primary50);
        color: white;
        width: 40%;
        font-size: 14px;
        height: 80%;
    }
    
    .pallette{
        width: 237px !important;
    }

    @media (min-width: 1600px) {

        .belowButtons{
            width: 533px;
        }

        
        #CoverImageDrop{
            .dropImageCircle{
                // height: 200px;
                height: 276.87px;
            }
        }
    }
}


#serviceTypeScreen{
    width: 100%;
    padding: none;

        .caption{
            color: #000;
        }

        
        .Heading22R{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 20%;
            margin-left: 20%;
            width: 70%;
        }

        .singleServiceType{
            height: 40px;
            min-width: 100%;
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            border-radius: 8px;
            border: 1px solid color(neutral100);
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            color: color(neutral60);
            background-color: white;

        }

        .singleServiceType:hover{
            border: 1px solid color(primary50);
            color: color(primary50);
        }

        .active{
            border: 1px solid color(primary50);
            color: color(primary50);
        }


}


#onlineService{
    width: 100%;
    padding: none;

        .caption{
            color: #000;
        }

        
        .Heading22R{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 20%;
            margin-left: 20%;
            width: 70%;
        }

        .singleServiceType{
            height: 70px;
            min-width: 100%;
            border-radius: 8px;
            padding: 8px 24px 8px 24px;
            border-radius: 8px;
            border: 1px solid color(neutral100);
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            color: color(neutral60);
            background-color: white;

        }

        .singleServiceType:hover{
            border: 1px solid color(primary50);
            color: color(primary50);
        }

        .active{
            border: 1px solid color(primary50);
            color: color(primary50);
        }


}



#locationScreen{
    width: 100%;
    margin-bottom: 100px;
        .caption{
            color: #000;
        }

        .Heading22R{
            line-height: 48px;
            font-weight: 300;
        }

        .cards{
            margin: auto;
            margin-top: 17%;
            margin-left: 20%;
            width: 70%;
        }


        .customInputField{
            min-width: 100%;
        }

        // .MuiInputLabel-shrink{
        //     color: color(neutral100);
        // }
        
        .nextBtn{
            background-color: color(primary50);
            color: white;
            width: 128px;
            font-size: 14px;
            height: 80%;
        }

}




@media screen and (max-width: 812px){

    .OnBoardingPage{
        #SignupHeader .logoBox {
            display: block;
        }

        #connectBrand {
            transition: transform 0.8s;
            .cardMain {
                width: 100%;
                margin: auto;
            }
            .cardsRow{
                width: 100%;
            }
            .cards {
                width: 120px;
                height: 150px;
            }
            .cardsSel {
                width: 120px;
                height: 150px;
            }

            .circle {
                width: 40px;
                margin-top: 38px !important;
                height: 40px;
            }
        }
        
        .scaleClass {
            transform: translate(6%, 65%);
            background: none;
            z-index: -1 !important;
            overflow-x: hidden;


            .cardsRow {
                width: 80% !important;
                margin: auto;
            }
        }


        .mobileCol {
            width: 80% !important;
            margin: auto;
        }
    }
}


#checkoutScreen{
    .checkoutType{
        background: #FFFFFF;
        box-shadow: 0px 6px 16px rgba(47, 128, 237, 0.16);
        border-radius: 6px;
        padding: 16px;
        width:  100%;
        outline: 1px solid color(neutral10);
        min-height: 150px;
    }

    .redemptionImage{
        width: 100%;
    }

    .checkoutType:hover{
        outline: 1px solid color(primary50);
        transition: ease 0.2s;
        cursor: pointer;
    }

    .activeCheckout{
        outline: 2px solid color(primary50);
        background: #E8EFF7;
    }

    .contentPart{
        padding: 40px 40px 0px 40px;
    }
}

#welcomeScreen{
    padding: 0px 13.625vw;


    .checkoutType{
        background: #FFFFFF;
        box-shadow: 0px 6px 16px rgba(47, 128, 237, 0.16);
        border-radius: 6px;
        padding: 40px 0px;
        width: 23.4375vw;
        border: 1px solid color(neutral10);
    }

    .redemptionImage{
        width: 100%;
    }

    .checkoutType:hover{
        border: 2px solid color(primary50);
        transition: ease 0.2s;
    }

    .activeCheckout{
        border: 2px solid color(primary50);
    }

    .contentPart{
        padding: 40px 40px 0px 40px;
    }

    .userDetailBox{
        width: 70%;
    }
}

#brandNameScreen, #SetupProgram, #welcomeScreen{
    height: calc( 100vh - 125px);

    .loyaltyTypeBox:hover{
        outline: 1px solid color(primary50);
        transition: ease 0.2s;
    }

    .activeCheckout{
        background-color: #E8EFF7 !important;
        outline: 2px solid color(primary50) !important;
    }
}