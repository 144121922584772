#sideStepper {
    width: 243.47px;
    border-right: 1px solid color(neutral20);
    height: 100vh;

    .contentFrame {
        margin-top: 40px;
        width: 76.5%;
        height: 315px;
        margin-left: 24px;
    }

    .Heading2 {
        margin-top: 16px;
        font-weight: 600;
    }
    .savebtn {
        float: right;
        cursor: pointer;
        border-bottom: 1px solid color(neutral20);
    }
    .circle {
        width: 35px;
        height: 35px;
        border-radius: 35px;
        background-color: color(white);
    }
    .col-8 {
        font-size: 14px;
        font-weight: 500;
        color: color(neutral80);
        margin-top: 5px;
    }

    .items {
        border-radius: 24px;
        padding-bottom: 11px;
        padding-top: 11px;
        padding-left: 8px;
        width: 100%;
    }

    .navtext {
        font-size: 12px;
        font-weight: normal;
        color: color(neutral80);
    }
    .icons {
        float: right;
        margin-right: 8px;
    }
    .active {
        border: 0.5px solid #1AC4D1;  
        .icons {
            display: none;
        }
    }
}