



@import url('https://fonts.googleapis.com/css?family=Heebo:100');


#NPid{

// Mixins
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin border-radius($tl, $tr: $tl, $br: $tl, $bl: $tl) {
  -moz-border-radius: $tl $tr $br $bl;
  -webkit-border-radius: $tl $tr $br $bl;
  border-radius: $tl $tr $br $bl;
}

@mixin filter($value) {
  -webkit-filter: $value;
  -moz-filter: $value;
  -o-filter: $value;
  filter: $value;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}

// Base Styles
html {
  @include box-sizing(border-box);
  font-family: 'Heebo', sans-serif;
}

html, body {
  height: 100%;
}

*, *:after, *:before {
  @include box-sizing(inherit);
}

body {
  margin: 0;
  background: #EAEAEA;
}

.container {
  // position: absolute;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
}

.iphone {
  position: absolute;
  top: 50px;
  right: 0px;
  height: 875px;
  width: 423px;
  padding: 0 15px;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  background: #111;
  border: 5px solid #3D3D3D;
  @include border-radius(50px);
  @include box-shadow(inset 0 0 3px 0 rgba(0,0,0,0.2), 0 0 0 1px #999, 0 0 30px 0px rgba(0,0,0,0.7));
  @include scale(0.6);

  .buttons {
    .silent, .vol-up, .vol-down, .sleep {
      background: #111;
      position: absolute;
      @include border-radius(2px, 0, 0, 2px);
    }
    .silent {
      height: 40px;
      width: 3px;
      top: 100px;
      left: -8px;
    }
    .vol-up, .vol-down {
      left: -8px;
      height: 60px;
      width: 4px;
    }
    .sleep {
      left: auto;
      right: -8px;
      top: 180px;
      height: 100px;
      width: 4px;
      @include border-radius(0, 2px, 2px, 0);
    }
    .vol-up {
      top: 170px;
    }
    .vol-down {
      top: 250px;
    }
  }

  .top {
    position: absolute;
    top: 4px;
    width: 100%;
    @include flexbox;
    @include justify-content(center);
    z-index: 4;
    @include filter(url('#goo'));
    .black-bar {
      position: absolute;
      height: 1px;
      width: 70%;
      padding-top: 2px;
      background: #111;
    }
    .iphone-top {
      position: relative;
      height: 40px;
      width: 55%;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
      @include border-radius(0, 0, 20px, 20px);
      background: #111;
      z-index: 2;
    }
  }

  .components {
    position: absolute;
    top: 20px;
    padding-left: 12px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    .speaker {
      height: 6px;
      width: 70px;
      margin: 0 30px;
      @include border-radius(6px);
      border-bottom: 1px solid #333;
      background: #222;
      z-index: 100;
    }
    .camera {
      height: 15px;
      width: 15px;
      @include border-radius(50%);
      border-bottom: 1px solid #444;
      background: radial-gradient(black, #333);
      opacity: 0.5;
      z-index: 4;
      .shine-left {
        position: absolute;
        height: 10px;
        width: 10px;
        margin: 2px;
        border-left: 4px solid dodgerblue;
        border-right: transparent;
        background: black;
        @include border-radius(50%);
        @include filter(blur(1.8px));
        &:before {
          content: '';
          position: absolute;
          right: 0px;
          height: 8px;
          width: 8px;
          border-right: 2px solid white;
          @include border-radius(4px);
        }
      }
    }
  }

  .top-bar, .bottom-bar {
    position: absolute;
    left: -5px;
    height: 15px;
    width: 423px;
    border-left: 5px solid #111;
    border-right: 5px solid #111;
  }
  .top-bar {
    top: 65px;
  }
  .bottom-bar {
    bottom: 65px;
  }

  .screen {
    position: absolute;
    height: calc(100% - 12px);
    width: calc(100% - 12px);
    margin: 0 auto;
    border: 2px solid rgba(0,0,0,0.9);
    @include border-radius(40px);
    overflow: hidden;
    .stuff {
      height: 100%;
      width: 100%;
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      & * {
        z-index: 200;
      }
    }
    .info {
      position: absolute;
      top: 140px;
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(center);
      z-index: 200;
    }
    .time {
      position: absolute;
      top: 8px;
      left: 30px;
      line-height: 1px;
      color: white;
      font-size: 1.2em;
      font-weight: 200;
    }
    .lock-lock {
      position: absolute;
      top: 95px;
      height: 26px;
      width: 34px;
      background: white;
      @include border-radius(3px);
      &:before {
        content: '';
        position: absolute;
        top: -18px;
        right: 4px;
        height: 30px;
        width: 26px;
        border: 4px solid white;
        @include border-radius(26px);
      }
    }
    .lock-carrier, .lock-time, .lock-date {
      color: white;
    }
    .lock-time {
      margin: 2rem 0 1.6rem 0;
      line-height: 3rem;
    }
    .lock-carrier {
      position: absolute;
      top: 11px;
      left: 42px;
      font-weight: 200;
      font-size: 0.9em;
      z-index: 200;
    }
    .lock-time {
      font-size: 6em;
      font-weight: 100;
      color: white;
    }
    .lock-date {
      font-weight: 100;
      font-size: 1.5em;
    }
    .lock-swipe, .lock-bar, .service {
      position: absolute;
      font-family: "Helvetica Neue", sans-serif;
    }
    .lock-swipe {
      bottom: 25px;
      font-size: 1.1em;
      font-weight: 100;
      color: white;
    }
    .lock-bar {
      bottom: 10px;
      height: 4px;
      width: 45%;
      @include border-radius(2px);
      background: white;
    }
    .service {
      top: 12px;
      left: 12px;
      height: 14px;
      width: 25px;
      @include flexbox;
      @include justify-content(space-between);
      @include align-items(flex-end);
      .bar {
        width: 4px;
        @include flexbox;
        @include border-radius(1px);
        background: white;
        &:first-child {
          height: 2px;
        }
        &:nth-child(2) {
          height: 6px;
        }
        &:nth-child(3) {
          height: 10px;
        }
        &:last-child {
          height: 14px;
        }
      }
    }
    .battery {
      position: absolute;
      top: 11px;
      right: 32px;
      height: 16px;
      width: 32px;
      border: 1px solid white;
      @include border-radius(2px);
      @include flexbox;
      @include align-items(center);
      z-index: 200;
      .nub, .energy {
        position: absolute;
        background: white;
      }
      .nub {
        top: 4px;
        right: -4px;
        height: 6px;
        width: 2px;
        @include border-radius(0, 4px, 4px, 0);
      }
      .energy {
        left: 1px;
        height: 12px;
        width: 18px;
        @include border-radius(1px, 0, 0, 1px);
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .iphone {
    @include scale(0.35);
  }
}





// 
// 
// 
// Previous styles remain the same...

.notification-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.8);
  // z-index: 300;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;

  .notification {
    width: 90%;
    // background: rgba(255, 255, 255, 0.9);
    border-radius: 9px;
    background: rgba(37, 37, 37, 0.60);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    color: #FFF;

    // border-radius: 15px;
    padding: 15px;
    // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    
    position: absolute;
    bottom: 40px;

    .notification-time {
      font-size: 9px;
      // color: #666;
      color: #FFF;
      // text-align: center;
      // margin-bottom: 10px;
    }

    .notification-content {
      display: flex;
      // align-items: center;

      .notification-logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .notification-text {
        flex: 1;

        .notification-heading {
          font-size: 15px;
          font-weight: bold;
          // color: #000;
          color: #FFF;
          margin-bottom: 5px;
        }

        .notification-description {
          font-size: 15px;
          // color: #555;
          color: #FFF;
        }
      }
    }
  }
}



.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{padding: 20px !important;}
.css-j204z7-MuiFormControlLabel-root{margin-bottom: 0px !important;}
.css-i4bv87-MuiSvgIcon-root{font-size: 20px !important;}

}