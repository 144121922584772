#Tier{
    .heading{
        color:  #2E3039;
font-family: Balto;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 36px; 
text-transform: capitalize;
    }

    .paragraph{
        color:  #4B4E5B;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
letter-spacing: 0.1px;
margin-top: 16px;
margin-bottom: 32px;
    }
}