#LeftPane {
    width: 245px;
    // background-color: color(white);
    height: calc(100vh - 72px);
    overflow-y: scroll;
    border-left: 0.5px solid color(neutral20);
    border-right: 0.5px solid color(neutral20);

    .copy {
        display: none;
    }

    .navigatorText {
        padding: 6px 0px 6px 16px;
        font-size: 11px;
        color: color(neutral10);
        opacity: 1;
        border-bottom: 1px solid color(neutral10);
    }

    .hover {
        .copy {
            display: block;
        }
    }

    .navigator {
        color: color(neutral80);
        border-bottom: 0.5px solid color(neutral20);
        padding: 6px 7px;
        display: flex;
        font-size: 14px;
        color: #7A869A;

        >div {
            cursor: pointer;
        }

        .active {
            color: color(neutral80);
            font-weight: 500;
        }
    }


    .properties {
        color: color(neutral90);
        display: flex;
        background-color: color(white);
        position: sticky;
        top: 0;
        left: 0;
        font-size: 12px;
        font-weight: 500;
        z-index: 10;
        line-height: 16px;
        height: 42px;
        padding: 2px 2px;
        border-bottom: 0.5px solid color(neutral20);
        border-right: 0.5px solid color(neutral20);

        .properties-options {
            width: 135px;
            color: color(neutral90);
            height: 38px;
            text-align: center;
            background-color: color(white);
            border-radius: 4px;
            cursor: pointer;

            &.active {
                background-color: color(primary50);
                color: color(white);
            }
        }
    }

    .elements {
        font-size: 12px;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: 0.1px;
        cursor: pointer;

        .body,
        .block {
            height: 32px;
            line-height: 16px;
            margin: 0px 1px;
            color: color(neutral70);

            svg {
                fill: color(neutral70);
            }
        }

        .body {
            padding: 8px 8px;
        }

        .block {
            padding: 8px 12px;
        }

    }

    .elements .block,
    .elements .body {
        &:hover {
            outline: 0.5px solid color(primary50);
            // border-radius: 4px;
            margin: 0px 1px;
            color: color(primary50);
        }

        &.focused {
            background-color: color(primary50);
            color: color(white);
            // border-radius: 4px;
        }

        &.focused svg {
            fill: white !important;
        }
    }

    .elements .block:hover svg {
        fill: color(primary50);
    }

    .elements .body:hover svg {
        fill: color(primary50);
    }
}

#NavigationComp {
    .contentBox {
        display: flex;
        flex-wrap: wrap;
    }

    .singleBox {
        display: flex;
        flex-direction: column;
        width: calc(100%/4);
        height: 92.5px;
        justify-content: center;
        align-items: center;
        padding: 16px;
        text-transform: capitalize;
        border: 0.5px solid color(neutral10);
        cursor: pointer;


       
    }

   

    // div:nth-child(3n+1) {
    //     background: #c00;
    // }

    // div:nth-child(3n) {
    //     background: #c00;
    // }
}